<template>
<footer id="contacto" class="bg-metal">
    <div class="py-5">
        <div class="container">
            <div class="row">
                <div class="col col-md-4 text-center mb-4 mb-md-0">
                    <img src="@/assets/img/logo-rebeca-lan.svg" alt="Fundación Rebeca Lan" height="70" class="mb-4">
                    <ul class="list-inline social-media">
                        <li class="list-inline-item"><a href="https://www.facebook.com/FundacionRebecaLan" target="_blank"><img src="@/assets/img/icon-round-fb.svg" alt="Facebook" class="img-fluid"></a></li>
                        <li class="list-inline-item"><a href="https://www.instagram.com/fundacionrebecalan/" target="_blank"><img src="@/assets/img/icon-round-instagram.svg" alt="Instagram" class="img-fluid"></a></li>
                        <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCudyW4Q-rfwRgZfmnh1Up5A" target="_blank"><img src="@/assets/img/icon-round-youtube.svg" alt="YouTube" class="img-fluid"></a></li>
                        <li class="list-inline-item"><a href="https://twitter.com/FundRebecaLan" target="_blank"><img src="@/assets/img/icon-round-twitter.svg" alt="Twitter" class="img-fluid"></a></li>
                    </ul>
                    Calle F No. 9635 int. 3 Col. Chulavista 22045<br>
                    Tijuana, Baja California<br>
                    <a href="tel:6643475641" target="_blank">664 347 5641</a>
                </div>
                <div class="col offset-md-1">
                    <h2 class="h4"><b>Subscription</b></h2>
                    <p class="mb-4">To receive more information from us.</p>
                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label sr-only">Name</label>
                                    <input type="text" placeholder="Name" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label class="form-label sr-only">Email</label>
                                    <input type="email" placeholder="Email" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label class="form-label sr-only">Subject</label>
                                    <input type="text" placeholder="Subject" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label sr-only">Message</label>
                                    <textarea rows="3" class="form-control" placeholder="Message"></textarea>
                                </div>
                                <div class="form-group form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1">I am not a robot</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-right">
                                <a type="submit" class="btn btn-secret">SIGN UP</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center ">
        <div class="container border-top">
            <div class="row py-2">
                <div class="col-md-7 d-flex align-items-center mb-3 mb-md-0">
                    <small>Todos los contenidos son propiedad de Fundación Rebeca Lan | <a href="/aviso-de-privacidad">Aviso de privacidad</a></small>
                </div>
                <div class="col-md-5 text-center text-md-right">
                    <img src="@/assets/img/payment-logos.png" alt="Mastercard, VISA, Paypal" class="payment-logos" height="35">
                </div>
            </div>
        </div>
    </div>
</footer>
</template>

<style scoped>

</style>
<script>

export default {
    name: 'Footer',
    data () {
      return {
      }
    },
    mounted() {
    },
    methods: {

    }
}
</script>